<template>
  <div class="Meeting">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" :moduleName="$route.meta.name">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section class="container">
            <el-row class="py-3">
              <el-col :span="24">
                <el-input clearable @clear="getProjectList" @keyup.enter.native="getProjectList" class="w-100"
                  :placeholder="$t('Events.94f@search_event')" v-model="search"></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="5" class="pb-3">
              <el-col :span="4">
                <el-date-picker style="width: 100%" clearable v-model="date" @change="getProjectList" type="date"
                  :placeholder="$t('Events.c0f@date')" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-col>
              <el-col :span="4" class="pb-3">
                <el-select clearable @clear="getProjectList" v-model="country_id" @change="getProjectList"
                  :placeholder="$t('Events.e97@country')">
                  <el-option v-for="(v, i) in countryList" :key="i" :label="v.name" :value="v.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="16">
                <OrganazationSel @getOrganizationParams="getOrganizationParams" />
              </el-col>
            </el-row>
            <div class="tab-btn-my">
              <tab-nav :tabs="true" id="myTab-two" class="tabsWidthScroll">
                <tab-nav-items :active="active == 'explore'" href="#explore" title="Explore"
                  @click.native="((page = 1), (type = 0)), getProjectList()" />
                <tab-nav-items :active="active == 'my'" href="#my" title="My Project"
                  @click.native="((page = 1), (type = 1)), getProjectList()" />
              </tab-nav>
            </div>
          </section>
        </iq-card>
      </template>
    </FullBanner>

    <tab-content>
      <tab-content-item :active="active == 'explore'" aria-labelled-by="prerecording-tab-two">
        <div class="container">
          <b-row v-if="loading">
            <b-col v-for="(item, index) in 12" :key="index" md="6" lg="4">
              <el-skeleton animated>
                <template slot="template">
                  <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                    <el-skeleton-item variant="image" style="width: 100%; height: 160px;" />
                    <div style="padding: 22px 10px 20px;" class="d-flex flex-column">
                      <el-skeleton-item variant="text" style="width: 60%;margin-bottom: 20px;" />
                      <el-skeleton-item variant="text" style="width: 40%;margin-bottom: 20px;" />
                      <el-skeleton-item variant="text" style="width: 60%" />
                    </div>
                  </iq-card>
                </template>
              </el-skeleton>
            </b-col>
          </b-row>
          <section class="Offline" v-if="projectList.length&&!loading">
            <b-row>
              <b-col v-for="(item, index) in projectList" :key="index" md="6" lg="4" class="cursor-pointer"
                @click="$router.push(`/project-detail?id=${item.id}&type=1`)">
                <Prerecording :cardInfo="item" />
              </b-col>
            </b-row>
          </section>
          <section v-if="!projectList.length" class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
            <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" v-if="!loading" />
          </section>
        </div>
      </tab-content-item>
      <tab-content-item :active="active == 'my'" aria-labelled-by="prerecording-tab-two">
        <div class="container">
          <section class="Offline" v-if="projectList.length&&!loading">
            <b-row>
              <b-col v-for="(item, index) in projectList" :key="index" md="6" lg="4" class="cursor-pointer"
                @click="$router.push(`/project-detail?id=${item.id}&type=1`)">
                <Prerecording :cardInfo="item" />
              </b-col>
            </b-row>
          </section>
          <section v-if="!projectList.length" class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
            <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" v-if="!loading" />
          </section>
        </div>
      </tab-content-item>
    </tab-content>

    <div v-if="projectList.length" class="d-flex justify-content-end container">
      <el-pagination background layout="prev, pager, next" :page-size="size" @current-change="handleCurrentChange"
        :current-page.sync="page" :total="total" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import FullBanner from '@/components/ComComponents/FullBanner'
  import Prerecording from '../Event/components/Prerecording'
  import CountrySelection from '@/components/ComComponents/CountrySelection'
  import Pagination from "@/components/ComComponents/Pagination";
  import OrganazationSel from "@/components/ComComponents/OrganazationSel";

  export default {
    mounted() { },
    components: {
      FullBanner,
      CountrySelection,
      Prerecording,
      Pagination,
      OrganazationSel,
    },
    data() {
      return {
        active: 'explore',
        type: 0,
        projectList: [],

        search: "",
        country_id: "",
        countryList: [],
        date: "",


        page: 1,
        size: 12,
        total: 0,
        loading: true
      }
    },
    created() {
      this.getProjectList();
      this.getAddress()
    },
    methods: {
      // 获取国家列表
      async getAddress(level = 2) {
        const params = {
          level
        }
        let {
          status,
          data
        } = await this.$http.getAddress(params)
        if (status == 200) {
          this.countryList = data;
        }
      },
      getOrganizationParams(data) {
        let OrganData = JSON.parse(JSON.stringify(data));
        let level = OrganData.level.id;
        switch (level) {
          case 0: {
            // jci
            this.organization_id = OrganData.jci.id;
            this.getProjectList();
            break;
          }
          case 4: {
            // area
            this.organization_id = OrganData.area.id;
            this.getProjectList();
            break;
          }
          case 2: {
            // nation
            this.organization_id = OrganData.national.id;
            this.getProjectList();
            break;
          }
          case 3: {
            // region
            this.organization_id = OrganData.regional.id;
            this.getProjectList();
            break;
          }
          case 1: {
            // local
            this.organization_id = OrganData.local.id;
            this.getProjectList();
            break;
          }
        }

      },
      handleCurrentChange(page) {
        this.page = page;
        this.getProjectList();
      },

      // 获取会议列表
      async getProjectList() {
        this.loading = true
        let res = await this.$http.projectList({
          type: this.type,
          keyword: this.search,
          date: this.date,
          country_id: this.country_id,
          organization_id: this.organization_id,
          per_page: this.size,
          page: this.page
        })
        if (res.status == 200) {
          this.total = res.data.total;
          this.projectList = res.data.data || []
        }
        setTimeout(() => {
          this.loading = false
        }, 0);
      },

    }

  }

</script>
<style lang="scss" scoped>
  .Meeting {
    min-height: 100vh;

    .headerContent {
      .content {
        padding-top: 28px;

        .searchBox {
          padding: 0 8px;
          position: relative;

          img {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }

        .selectBox {
          margin: 14px 0 50px;
        }
      }
    }
  }

  .tab-btn-my {
    display: flex;
    justify-content: space-between;
    align-items: center;

    #myTab-two {
      margin-bottom: 0;
    }

    .toMyMeeting {
      width: 130px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      border: 1px solid #50b5ff;
      background-color: #f5fbff;
      color: #50b5ff;
      cursor: pointer;

      >i {
        margin-left: 10px;
      }
    }
  }

  ::v-deep .el-date-editor--date .el-input__inner,
  ::v-deep .el-select .el-input__inner,
  ::v-deep .el-input__inner {
    height: 45px;
    border-radius: 11px;
  }
</style>